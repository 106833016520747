<template>
  <div>
    <the-client-layout-header headline="Performance" />

    <v-row class="d-flex ma-2" justify="center">
      <v-col cols="12" sm="6" md="5" lg="4"
        ><client-performance-chart-allocation
          :performance-data="performanceData"
        />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12"
        ><client-performance-assets
          :asset-class-items="assetClassItems"
          :performance-data="performanceData"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import TheClientLayoutHeader from '@/components/TheClientLayoutHeader.vue'
import ClientPerformanceAssets from '@/components/ClientPerformanceAssets.vue'
import ClientPerformanceChartAllocation from '@/components/ClientPerformanceChartAllocation.vue'

export default {
  name: 'clientPerformance',

  beforeRouteEnter(routeTo, routeFrom, next) {
    // making sure, when navigating to this page, the slider allways moves to "PERFORMANCE" ( tab = 3 )
    store.commit('SET_CLIENT_LAYOUT_TAB', 3)
    next()
  },

  components: {
    TheClientLayoutHeader,
    ClientPerformanceAssets,
    ClientPerformanceChartAllocation
  },

  data() {
    return {}
  },

  computed: {
    // create a set of all available asset classes (to be used in New Asset - select Class)
    assetClassItems() {
      let list = []
      Object.entries(this.clientAssets).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.adminDefinitionsAssets).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.advisorDefinitionsAssets).forEach(([, val]) => {
        list.push(val.class)
      })
      return Array.from(new Set(list)).sort()
    },

    // Asset Expansion Panel tableData Computed Property
    // Return 1. Array of active classes (for Tabs)
    //        2. Array of Asset-Objects per class
    //        3. NetValueSum of Assets per class
    //        4. NetValue of Asset-Object (Asset - Liability) asset.netValue
    //        5. NetIncome of Asset-Object (Income - Expenses) asset.netIncome
    //        6. Yield of Asset-Object obj.yield (current p.a.)
    performanceData() {
      let data = []
      let classes = [
        'Precious Metal',
        'Property',
        'Investment',
        'Crypto Currency'
      ]
      // get all active classes
      // Object.entries(this.clientAssets).forEach(([, obj]) => {
      //   classes.push(obj.class)
      // })
      // let activeClasses = Array.from(new Set(classes))
      // build asset object data for each drawer
      Object.entries(classes).forEach(([, activeClass]) => {
        let marketValueSum = 0
        let capitalGainSum = 0
        let incomeSum = 0
        let assets = []
        // calculate netValue / netValueSum
        Object.entries(this.clientAssets).forEach(([, asset]) => {
          if (activeClass == asset.class) {
            let liabilityRelated = 0
            let incomeRelated = 0
            let expenseRelated = 0

            // Liability
            Object.entries(this.clientLiabilities).forEach(([, liability]) => {
              if (liability.idRelated == asset.id) {
                liabilityRelated += liability.balanceDueTodayApp
              }
            })
            asset.netValue = asset.priceTodayApp - liabilityRelated

            // Income
            Object.entries(this.clientIncomeSources).forEach(([, income]) => {
              if (income.idRelated == asset.id) {
                incomeRelated += income.incomePaTodayApp
              }
            })

            // Expenses
            Object.entries(this.clientSpendingStreams).forEach(
              ([, expense]) => {
                if (expense.idRelated == asset.id) {
                  expenseRelated += expense.spendingPaTodayApp
                }
              }
            )
            //
            asset.income = incomeRelated - expenseRelated
            asset.yield = (asset.income / asset.priceTodayApp) * 100
            assets.push(asset)
            marketValueSum += asset.priceTodayApp
            capitalGainSum +=
              (asset.gainTodayLocal / this.fxRateToday[asset.currencyLocal]) *
              this.fxRateToday[this.currencyApp]

            incomeSum += asset.income
          }
        })

        data.push({
          class: activeClass,
          incomeSum: incomeSum,
          marketValueSum: marketValueSum,
          capitalGainSum: capitalGainSum,
          yield: (incomeSum / marketValueSum) * 100,
          assets: assets
        })
      })
      data.sort(function(a, b) {
        return b.marketValueSum - a.marketValueSum
      })
      return data
    },

    ...mapState(['client', 'clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
