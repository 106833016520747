<template>
  <div>
    <v-card width="500" class="mt-5" outlined>
      <v-card-title class="headline font-weight-normal primary--text">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <div class="text-center">
              Investments Allocation
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <div id="chart" class="text-left mt-n4 mb-4">
        <apexchart
          type="donut"
          width="350"
          height="350"
          :options="selectedData.chartOptions"
          :series="selectedData.series"
        ></apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'clientPerformanceChartAllocation',

  props: {
    performanceData: { type: Array }
  },

  data() {
    return {}
  },

  methods: {},

  computed: {
    selectedData() {
      let data = {
        chartOptions: {},
        series: [],
        labels: [],
        text: ''
      }
      let currencyApp = this.currencyApp
      let totalInvestmentSum = 0
      let percent = []
      Object.entries(this.performanceData).forEach(([, obj]) => {
        data.labels.push(obj.class)
        data.series.push(obj.marketValueSum)
      })
      Object.entries(data.series).forEach(([, val]) => {
        totalInvestmentSum += val
      })
      Object.entries(data.series).forEach(([, val]) => {
        percent.push(Math.floor((val * 10000) / totalInvestmentSum) / 100)
      })
      data.chartOptions = {
        colors: ['#f47d20', '#424242', '#bdbdbd', '#b00020'],
        labels: data.labels,
        dataLabels: {
          enabled: false
        },
        yaxis: {
          type: 'numeric',
          labels: {
            formatter: function(value) {
              return (
                value.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }) +
                ' ' +
                currencyApp
              )
            }
          }
        },
        legend: {
          show: true,
          showForNullSeries: true,
          position: 'right',
          // formatter: function(seriesName, opts) {
          //   return percent[opts.seriesIndex] <= 9
          //     ? ['  ', percent[opts.seriesIndex], ' % ', seriesName]
          //     : [percent[opts.seriesIndex], ' %  ', seriesName]
          // },
          offsetY: 0,
          offsetX: 0
        }
      }

      return data
    },

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
