<template>
  <div>
    <v-card class="mt-5" flat>
      <template>
        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="(obj, i) of performanceData"
            :key="i"
            class="elevation-0 info mb-2"
          >
            <v-expansion-panel-header
              ><v-card
                flat
                class="transparent subtitle-1 font-weight-medium primary--text text-left"
              >
                {{ obj.class }}
              </v-card>
              <v-spacer />
              <!-- <v-card
                flat
                class="transparent subtitle-1 font-weight-medium primary--text text-right"
              >
                Market Value:
                {{
                  obj.marketValueSum.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
                {{ currencyApp }}
              </v-card> -->
              <v-spacer />
              <v-spacer />
              <v-spacer />
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="obj.assets"
                item-key="i"
                hide-default-footer
              >
                <template v-slot:top> </template>

                <template v-slot:item.name="{ item }">
                  <v-btn
                    text
                    retain-focus-on-click
                    color="success"
                    class="text-capitalize ml-n4"
                    @click="editItem(item)"
                    ><div v-if="item.class == 'Precious Metal'">
                      {{ item.name }}
                      {{
                        item.catText
                          ? ' / ' +
                            item.catText +
                            ' / ' +
                            (item.amount * item.unit).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              }
                            ) +
                            ' oz '
                          : ''
                      }}
                    </div>
                    <div v-else-if="item.class == 'Crypto Currency'">
                      {{ item.name }}
                      {{
                        item.catText
                          ? ' / ' + item.amount + ' ' + item.catText
                          : ''
                      }}
                    </div>
                    <div v-else>
                      {{ item.name }}
                      {{ item.catText ? ' / ' + item.catText : '' }}
                    </div>
                  </v-btn>
                </template>

                <template v-slot:item.costValueLocal="{ item }">
                  <v-card-text
                    >{{
                      item.costValueLocal.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                    {{ item.currencyLocal }}</v-card-text
                  >
                </template>

                <template v-slot:item.priceTodayLocal="{ item }">
                  <v-card-text
                    >{{
                      item.priceTodayLocal.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                    {{ item.currencyLocal }}</v-card-text
                  >
                </template>

                <template v-slot:item.income="{ item }">
                  <v-card-text
                    >{{
                      item.income.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                    {{ currencyApp }}
                    ({{
                      item.yield.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                    %)</v-card-text
                  >
                </template>

                <template v-slot:item.gainTodayLocalPercent="{ item }">
                  <v-card-text
                    >{{
                      (
                        (item.gainTodayLocal /
                          fxRateToday[item.currencyLocal]) *
                        fxRateToday[currencyApp]
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                    {{ currencyApp }}
                    ({{
                      item.gainTodayLocalPercent.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                    %)</v-card-text
                  >
                </template>

                <template v-slot:no-data>
                  <v-card flat>
                    <v-card-text class="title font-weight-regular">
                      There are no Assets defined yet ...
                    </v-card-text>
                  </v-card>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-card>

    <v-dialog persistent v-model="dialog" max-width="450px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="ml-3">
            <span class="headline mt-4">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-select
                    v-if="itemsOwner.length > 1"
                    v-model="asset.owner"
                    :items="itemsOwner"
                    label="Asset Owner"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>
                <v-col cols="12" sm="8" md="8" class="mt-n8">
                  <v-select
                    v-model="asset.class"
                    :items="assetClassItems"
                    label="Asset Class"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>

                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-select
                    v-model="asset.catText"
                    :items="itemsCategory"
                    item-text="text"
                    item-value="text"
                    label="Asset Sub-Category"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-text-field
                    v-model.number="growthValue"
                    :rules="numberRules"
                    label="Est. growth (% p.a.)"
                    class="primary--text"
                    min="0"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-text-field
                    v-model="asset.name"
                    :rules="nameReqRules"
                    label="Asset Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-select
                    v-model="asset.currencyLocal"
                    :items="itemsFiat"
                    label="Currency"
                    class="primary--text"
                  ></v-select>
                </v-col>

                <div
                  v-if="
                    asset.class == 'Cash' ||
                      asset.catText == 'Cash' ||
                      asset.class == 'Insurance'
                  "
                ></div>
                <div v-else>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n6 pl-6">
                      <template>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          top
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="asset.costDate"
                              label="Date of purchase"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="asset.costDate"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1999-01-01"
                            @change="save"
                          ></v-date-picker>
                        </v-menu>
                      </template>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-n6 pr-6">
                      <v-text-field
                        v-model.number="asset.costValueLocal"
                        :rules="numberRules"
                        label="Cost"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="asset.class == 'Precious Metal'">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n3 pl-6">
                      <v-text-field
                        v-model.number="asset.amount"
                        :rules="numberReqRules"
                        label="Amount"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-n3 pr-6">
                      <v-select
                        v-model="asset.unit"
                        :items="itemsUnitPM"
                        label="Unit"
                        class="primary--text"
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="asset.class == 'Crypto Currency'">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n3 pl-6">
                      <v-text-field
                        v-model.number="asset.amount"
                        :rules="numberReqRules"
                        label="Amount"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-n3 pr-6">
                      <v-text-field
                        v-model="asset.catText"
                        label="Unit"
                        class="primary--text"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div
                  v-if="
                    asset.class == 'Precious Metal' ||
                      asset.class == 'Crypto Currency'
                  "
                >
                  <v-col cols="12" sm="12" md="12" class="mt-n8 mr-n4"> </v-col>
                </div>
                <div v-else>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n3 pl-6">
                      <template>
                        <v-menu
                          ref="menuSec"
                          v-model="menuSec"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          top
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="asset.priceDate"
                              label="Date of valuation"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="asset.priceDate"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1999-01-01"
                            @change="saveSec"
                          ></v-date-picker>
                        </v-menu>
                      </template>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-n3 pr-6">
                      <v-text-field
                        v-model.number="asset.priceValueLocal"
                        :rules="numberReqRules"
                        :label="LabelPriceValueLocal"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="asset.class == 'Insurance'">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="mt-n8  pl-6">
                      <template>
                        <v-menu
                          ref="menuTrd"
                          v-model="menuTrd"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          top
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="asset.maturityDate"
                              label="Date of lumpsum payment"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="asset.maturityDate"
                            max="2070-12-31"
                            :min="new Date().toISOString().substr(0, 10)"
                            @change="saveTrd"
                          ></v-date-picker>
                        </v-menu>
                      </template>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="mt-n8  pl-6"> </v-col>
                  </v-row>
                </div>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn class="error--text mb-4 ml-6" @click="deleteItem" text
              >Delete</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn class="primary--text mb-4" @click="close" text>Cancel</v-btn>
            <div v-if="formTitle == 'Edit Asset'">
              <v-btn
                color="success"
                :disabled="!valid"
                @click="updateItem"
                class="mr-4 mb-4"
                text
                >Update</v-btn
              >
            </div>
            <div v-else>
              <v-btn
                color="success"
                :disabled="!valid"
                @click="createItem"
                class="mr-4 mb-4"
                text
                >Create</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'clientPerformanceAssets',

  props: {
    assetClassItems: { type: Array },
    performanceData: { type: Array }
  },

  data() {
    return {
      valid: true,
      dialog: false,
      menu: false,
      menuSec: false,
      menuTrd: false,
      editedId: -1,
      asset: StaticDataService.getAsset(),
      itemsFiat: StaticDataService.getUnitFiat(),
      itemsUnitPM: StaticDataService.getUnitPreciousMetal(),
      itemsOwner: this.$store.getters.clientAccHolderItems,
      headers: [
        {
          text: 'Asset Name / Category',
          align: 'left',
          value: 'name',
          class: 'subtitle-2 font-weight-medium primary--text'
        },
        {
          text: 'Cost (local)',
          align: 'right',
          value: 'costValueLocal',
          class: 'subtitle-2 font-weight-medium primary--text pr-4'
        },
        {
          text: 'Market value (local)',
          align: 'center',
          value: 'priceTodayLocal',
          class: 'subtitle-2 font-weight-medium primary--text'
        },
        {
          text: 'Income',
          align: 'center',
          value: 'income',
          class: 'subtitle-2 font-weight-medium primary--text'
        },
        {
          text: 'Unrealised capital gain',
          align: 'center',
          value: 'gainTodayLocalPercent',
          class: 'subtitle-2 font-weight-medium primary--text'
        }
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      numberRules: [
        v => !v || /^\d{0,10}(\.\d{1,2})?$/.test(v) || 'Requires numbers only'
      ],
      numberReqRules: [
        v => !!v || 'Number is required',
        v => !v || /^\d{0,10}(\.\d{1,2})?$/.test(v) || 'Requires numbers only'
      ]
    }
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    menuSec(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    menuTrd(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },

  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    saveSec(date) {
      this.$refs.menuSec.save(date)
    },
    saveTrd(date) {
      this.$refs.menuTrd.save(date)
    },
    createItem() {
      if (this.$refs.form.validate()) {
        this.setGrowthRate(this.growthValue)
        this.$store.dispatch('createAsset', this.asset).then(() => {
          this.close()
        })
      }
    },
    setGrowthRate(value) {
      this.asset.growthRate = value
    },
    editItem(item) {
      this.asset = Object.assign({}, item)
      this.editedId = item.id
      this.dialog = true
    },
    updateItem() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('updateAsset', this.asset).then(() => {
          this.close()
        })
      }
    },
    deleteItem() {
      this.$store.dispatch('deleteAsset', this.asset).then(() => {
        this.close()
      })
    },
    close() {
      this.dialog = false
      this.asset = StaticDataService.getAsset()
      this.$refs.form.resetValidation()
      this.editedId = -1
    }
  },

  computed: {
    formTitle() {
      return this.editedId === -1 ? 'New Asset' : 'Edit Asset'
    },

    LabelPriceValueLocal() {
      return this.asset.class == 'Insurance' ||
        this.asset.catText == 'Cash' ||
        this.asset.class == 'Cash'
        ? 'Cash Value'
        : 'Price'
    },

    itemsCategory() {
      let items = []
      Object.entries(this.assetCategoryItems).forEach(([, val]) => {
        if (this.asset.class == val.class) {
          items = val.category
        }
      })
      return items
    },

    assetCategoryItems() {
      let items = []
      this.assetClassItems.forEach(item => {
        let obj = { class: item, category: [] }
        let cat = []
        Object.entries(this.clientAssets).forEach(([, val]) => {
          if (val.class == obj.class) {
            cat.push(val.catText)
          }
        })
        Object.entries(this.adminDefinitionsAssets).forEach(([, val]) => {
          if (val.class == obj.class) {
            cat.push(val.category)
          }
        })
        Object.entries(this.advisorDefinitionsAssets).forEach(([, val]) => {
          if (val.class == obj.class) {
            cat.push(val.category)
          }
        })
        obj.category = Array.from(new Set(cat))
        items.push(obj)
      })
      return items
    },

    growthValue: {
      // This getter only updates the estimated growth parameter in the dialog-box. To actually update the
      // asset.growthRate variable, the method setGrowthRate() has to be utilized before creating a new asset!
      get: function() {
        let value = 0
        Object.entries(this.advisorDefinitionsAssets).forEach(([, val]) => {
          if (
            this.asset.class == val.class &&
            this.asset.catText == val.category
          ) {
            value = val.growth
          }
        })
        return this.asset.growthRate != null ? this.asset.growthRate : value
      },
      // This setter only updates the asset.growthRate variable, when the input value actually changes!! E.g. if
      // the suggested assumption is chosen, no update of the variable asset.growthRate takes place. That's why
      // in addition the method setGrowthRate() is needed, as mentioned before under getter description.
      set: function(newValue) {
        this.asset.growthRate = newValue
      }
    },

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
